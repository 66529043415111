<!--
 * @Description: 客服服务信息详情
 * @Author: ChenXueLin
 * @Date: 2021-10-20 14:15:17
 * @LastEditTime: 2022-06-21 18:51:57
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'服务结果:' + detailInfo.serverResult"
            :showTime="false"
          >
          </detail-title-content>
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <el-tabs v-model="activeName">
              <el-tab-pane label="基本信息" name="one">
                <template>
                  <div>
                    <el-form label-width="140px" class="line-form">
                      <el-row>
                        <el-col :span="8">
                          <el-form-item label="服务信息ID：">
                            {{ detailInfo.serviceId }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="服务人名称：">
                            {{ detailInfo.serverName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="服务人角色：">
                            {{ detailInfo.serverRole }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="服务时间：">
                            {{ detailInfo.serverTimeString }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="期望解决时间：">
                            {{ detailInfo.expectSolveTimeString }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="关闭方式：">
                            {{ detailInfo.closeWay }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                    <el-form label-width="140px" class="line-form">
                      <el-row>
                        <el-col :span="8">
                          <el-form-item label="客户名称：">
                            {{ detailInfo.customerName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来去电人名称：">
                            {{ detailInfo.phoneCallName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="联系人类型：">
                            {{ detailInfo.isEmployeeName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来去电号码：">
                            {{ detailInfo.phoneNumber }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来去电类型：">
                            {{ detailInfo.phoneType }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="通话时长：">
                            <span> {{ detailInfo.callDuration }}</span>
                            <el-button
                              type="text"
                              @click="playVideo"
                              v-if="detailInfo.record"
                              >播放录音</el-button
                            >
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="关联单据：">
                            <el-button
                              type="text"
                              v-if="detailInfo.taskNum"
                              @click="toTaskDetail"
                              >{{ detailInfo.taskNum }}</el-button
                            >
                            <span v-else></span>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                  <div class="edit-title">服务信息</div>
                  <el-form label-width="140px" style="margin-top:15px;">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="一级服务类型：">
                          {{ detailInfo.firstServer }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="二级服务类型：">
                          {{ detailInfo.secondServer }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="三级服务类型：">
                          {{ detailInfo.thirdServer }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="四级服务类型：">
                          {{ detailInfo.fourthService }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="服务内容：">
                          {{ detailInfo.serverDetails }}
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </template>
              </el-tab-pane>
              <el-tab-pane
                label="关联单据"
                name="four"
                v-if="detailInfo.taskId"
              >
                <tree-chart :json="treeData" />
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 基本信息 end -->
        </div>
      </section>
    </div>
    <play-video
      :videoId="detailInfo.record"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video>
  </div>
</template>

<script>
import base from "@/mixins/base";
import detailTitleContent from "@/components/detailTitleContent";
import TreeChart from "@/components/treeChart";
import { getCustomerServiceDetail, associatedBills } from "@/api";
import { printError } from "@/utils/util";
import PlayVideo from "@/components/playVideo.vue";
export default {
  name: "customerInfoServiceDetail",
  mixins: [base],
  components: {
    detailTitleContent,
    TreeChart,
    PlayVideo
  },
  data() {
    return {
      detailInfo: {},
      dialogVisible: false,
      activeName: "one",
      transferColumnData: [
        {
          fieldName: "type",
          display: true,
          fieldLabel: "原持有人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "流转类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "新持有人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "所属人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "操作人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "操作时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "关联单据",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "关联单据类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //流转记录表头
      treeData: []
    };
  },
  computed: {
    btnType() {
      let status;
      if (this.detailInfo.serverResultCode == 1) {
        status = "greenBtn";
      } else {
        status = "yellowBtn";
      }
      return status;
    }
  },
  watch: {},
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.id = hasParamsRoute.params.serviceId;
      if (this.id) {
        this.queryDetail();
      }
    },
    //获取问题列表
    async queryDetail() {
      try {
        this.loading = true;
        let res = await getCustomerServiceDetail({ id: this.id });
        this.detailInfo = this.getFreezeResponse(res, {
          path: "data"
        });
        if (this.detailInfo.taskId) {
          this.getAssociatedBills();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取关联单据
    async getAssociatedBills() {
      try {
        let res = await associatedBills({
          taskId: this.detailInfo.taskId
        });
        //基本信息
        this.treeData = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //去任务单详情
    toTaskDetail() {
      let taskNum = this.detailInfo.taskNum;
      if (!taskNum) {
        this.$message.warning("无关联单号");
        return;
      }
      if (taskNum.indexOf("RWD") > -1) {
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            taskId: this.detailInfo.taskId
          }
        });
      }
      if (taskNum.indexOf("FWD") > -1) {
        this.routerPush({
          name: "serviceListManage/serviceDetail",
          params: {
            refresh: true,
            serviceId: this.detailInfo.taskId
          }
        });
      }
    },
    //播放录音
    playVideo() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  created() {
    this.initForm();
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  width: 100%;
  .edit-title {
    width: 100%;
    margin-top: 10px;
  }
  .line-form {
    margin-top: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  /deep/.el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
.table-wrapper {
  .repair-count {
    font-size: 14px;
    padding: 15px 15px 0px;
    box-sizing: border-box;
    & > :nth-child(1) {
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .elTable {
    margin-top: 15px;
  }
}
</style>
